<template>
  <div class="app-container">
    <div class="header">
      <el-button-group>
        <el-button
          :class="{ cur: temp.isReprint === 0 }"
          type="info"
          plain
          @click="temp.isReprint = 0"
          >原创</el-button
        >
        <el-button
          :class="{ cur: temp.isReprint === 1 }"
          type="info"
          plain
          @click="temp.isReprint = 1"
          >转载</el-button
        >
      </el-button-group>
      <el-button style="float: right; margin-right: 174px" @click="preView">预览</el-button>
      <el-button
        :loading="commitFlag"
        type="primary"
        style="float: right; margin-right: 10px"
        @click="handleSave"
        >{{ $t('button.preservation') }}</el-button
      >
      <el-button style="float: right" @click="$router.go(-1)">取消</el-button>
    </div>
    <el-form
      ref="dataForm"
      :model="temp"
      :inline="true"
      label-width="120px"
      label-position="right"
      class="form-container bgFFF"
    >
      <el-form-item label="封面图片:" style="display: block; width: 900px">
        <el-radio-group v-model="temp.coverType" @change="coverChange">
          <el-radio :label="0">单图模式</el-radio>
          <el-radio :label="1">三图模式</el-radio>
        </el-radio-group>
        <el-upload
          :on-remove="handleRemove"
          :on-exceed="handleExceed"
          :before-upload="beforeAvatarUpload2"
          :limit="coverLimit"
          :http-request="fnUploadRequest2"
          :file-list="fileList"
          action
          list-type="picture-card"
          :class="{ hide: hideUploadEdit }"
        >
          <div slot="tip" class="el-upload__tip">
            请上传图片，宽高比16：9，大小1M以内，支持格式.jpeg .jpg .png .gif .bmp
          </div>
          <i class="el-icon-plus" />
        </el-upload>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="标题:">
            <el-input
              v-model="temp.title"
              :rows="2"
              type="textarea"
              placeholder="请输入文章标题"
              maxlength="64"
              show-word-limit
              style="width: 350px"
            />
          </el-form-item>
          <el-form-item label="作者:">
            <el-input
              v-model="temp.author"
              autosize
              placeholder="请输入文章作者"
              maxlength="20"
              show-word-limit
              style="width: 350px"
            />
          </el-form-item>
          <el-form-item label="区域:">
            <el-cascader
              :options="cities"
              :props="{
                expandTrigger: 'hover',
                multiple: true,
                label: 'areaName',
                value: 'areaId',
                children: 'childrenAreas',
              }"
              :show-all-levels="false"
              v-model="temp.cityNames"
              collapse-tags
              clearable
              placeholder="全国"
            />
          </el-form-item>
          <el-form-item label="发布渠道:">
            <el-checkbox-group v-model="temp.releaseType" style="width: 350px">
              <el-checkbox :label="1">APP</el-checkbox>
              <el-checkbox :label="2">微信小程序</el-checkbox>
              <el-checkbox :label="4">支付宝小程序</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="分享标题:">
            <el-input
              v-model="temp.shareTitle"
              placeholder="请输入文章分享后的显示标题"
              maxlength="30"
              show-word-limit
              style="width: 350px"
            />
          </el-form-item>
          <el-form-item label="分享描述:">
            <el-input
              v-model="temp.shareDescribe"
              placeholder="请输入文章分享后的显示描述"
              maxlength="30"
              show-word-limit
              style="width: 350px"
            />
          </el-form-item>
          <el-form-item label="分享缩略图:">
            <el-upload
              :on-remove="handleRemoveForShare"
              :on-exceed="handleExceedForShare"
              :before-upload="beforeAvatarUpload"
              :limit="1"
              :http-request="fnUploadRequest3"
              :file-list="shareFileList"
              action=""
              list-type="picture-card"
              :class="{ hide: hideUploadEdit2 }"
            >
              <div slot="tip" class="el-upload__tip">
                请上传图片，宽高比16：9，大小100kB以内，支持格式.jpg .png .jpeg
              </div>
              <i class="el-icon-plus" />
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="简介:">
            <el-input
              v-model="temp.introduction"
              :rows="2"
              type="textarea"
              placeholder="请输入文章简介"
              maxlength="120"
              show-word-limit
              style="width: 350px"
            />
          </el-form-item>
          <el-form-item label="是否置顶:">
            <el-radio-group v-model="temp.isStick" style="width: 350px">
              <el-radio :label="1" value="1">是</el-radio>
              <el-radio :label="0" value="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="分类:">
            <el-cascader
              v-model="temp.categoryId"
              :options="types"
              :props="{ expandTrigger: 'hover', value: 'categoryId', label: 'nodeName' }"
              placeholder="请选择分类"
            />
          </el-form-item>
          <el-form-item label="阅读数:">
            <template>
              <div slot="label" class="label-slot">
                <el-tooltip
                  effect="dark"
                  content="说明：前台展示[阅读数]=基础值+用户实际操作值"
                  placement="top-start"
                >
                  <img src="./question.png" />
                </el-tooltip>
                <div>阅读数:</div>
                <div v-if="$route.name === 'infoEdit'" style="width: 100%; line-height: 0.2em">
                  ({{ temp.readNumber }})
                </div>
              </div>
              <el-input v-model="temp.readBasics" placeholder="基础值" style="width: 120px" />
              <span> + </span>
              <el-input
                v-model="temp.userRead"
                placeholder="实际值"
                style="width: 120px"
                disabled
              />
              <span> × </span>
              <el-input
                v-model.number="temp.readMultiple"
                placeholder="放大倍数"
                style="width: 120px"
              />
            </template>
          </el-form-item>
          <el-form-item label="点赞数:">
            <template>
              <div slot="label" class="label-slot">
                <el-tooltip
                  effect="dark"
                  content="说明：前台展示[点赞数]=基础值+用户实际操作值"
                  placement="top-start"
                >
                  <img src="./question.png" />
                </el-tooltip>
                <div>点赞数:</div>
                <div v-if="$route.name === 'infoEdit'" style="width: 100%; line-height: 0.2em">
                  ({{ temp.pointNumber }})
                </div>
              </div>
              <el-input v-model="temp.pointBasics" placeholder="基础值" style="width: 120px" />
              <span> + </span>
              <el-input
                v-model="temp.userPoint"
                placeholder="实际值"
                style="width: 120px"
                disabled
              />
              <span> × </span>
              <el-input
                v-model.number="temp.pointMultiple"
                placeholder="放大倍数"
                style="width: 120px"
              />
            </template>
          </el-form-item>
          <el-form-item v-if="temp.isReprint === 1" label="原文链接:">
            <el-input
              v-model="temp.jumpUrl"
              placeholder="请输入转发的原文地址"
              maxlength="100"
              style="width: 350px"
            />
          </el-form-item>
          <el-form-item label="定时发布时间:">
            <el-date-picker
              v-model="temp.upperTime"
              type="datetime"
              placeholder="非必填，按需计划任务"
            />
          </el-form-item>
          <el-form-item label="定时下架时间:">
            <el-date-picker
              v-model="temp.lowerTime"
              type="datetime"
              placeholder="非必填，按需计划任务"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div
        v-if="temp.isReprint === 0"
        style="border-bottom: 1px solid #dcdfe6; margin-bottom: 20px"
      />
      <el-form-item v-if="temp.isReprint === 0" style="display: block; width: 1200px">
        <el-upload
          :show-file-list="false"
          :http-request="fnUploadRequest"
          action
          style="float: left"
        >
          <el-button type="primary" size="mini" style="margin-right: 20px; margin-left: 20px"
            >上传资源</el-button
          >
        </el-upload>
        <span style="float: left"
          ><el-input
            v-model="imageUrl"
            style="width: 930px"
            placeholder="上传成功后资源的路径会显示在这里"
        /></span>
      </el-form-item>
      <el-form-item v-if="temp.isReprint === 0" label="内容:" style="width: 100%">
        <tinymce-editor ref="editor" v-model="temp.content" />
      </el-form-item>
    </el-form>
    <!-- ========================= -->
    <el-dialog :visible.sync="previewVisible" title="预览">
      <div class="preview-container">
        <el-card class="preview-wrapper">
          <div class="title">{{ temp.title }}</div>
          <div class="tip">
            <span class="type">资讯分类</span>
            <span>{{ this.$moment(new Date()).format("YYYY-MM-DD") }}</span>
          </div>
          <div class="con" style="line-height: 1.8em" v-html="temp.content" />
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import TinymceEditor from "@/components/tinymce-editor";
require("../../../static/reset.css");
export default {
  components: {
    TinymceEditor,
  },
  data() {
    return {
      temp: {
        isReprint: 0,
        isStick: 0,
        coverType: 0,
        title: "",
        author: "",
        cityNames: [[1]],
        releaseType: [1],
        shareTitle: "",
        shareDescribe: "",
        introduction: "",
        categoryId: "",
        readBasics: "",
        readMultiple: "",
        pointBasics: "",
        pointMultiple: "",
        jumpUrl: "",
        upperTime: "",
        lowerTime: "",
        content: "",
        userRead: 0,
        userPoint: 0,
      },
      cities: [],
      cityNames: [1],
      imageUrl: "",
      previewVisible: false,
      fileList: [],
      shareFileList: [],
      image: [],
      image2: [],
      coverLimit: 1,
      types: [],
      commitFlag: false,
      informationId: this.$route.query.informationId,
      hideUploadEdit: false,
      hideUploadEdit2: false,
    };
  },
  created() {
    this.queryCityList();
    this.getGradeTree();
    if (this.informationId) {
      this.getInfoDetail();
    }
  },
  methods: {
    // 获取分类列表
    getGradeTree() {
      const opt = {
        url: "/acb/2.0/informationCategory/tree",
        method: "get",
        data: {},
        success: (res) => {
          this.types = res.value;
        },
      };
      this.$request(opt);
    },
    // 获取城市列表
    queryCityList() {
      const opt = {
        url: "/acb/2.0/area/provinceWithCity",
        method: "get",
        data: {},
        success: (res) => {
          res.value[0].depth = 1;
          delete res.value[0].childrenAreas;
          this.cities = res.value;
        },
      };
      this.$request(opt);
    },
    // 处理表单数据
    handleFormData(temp) {
      const tempData = {};
      tempData.isReprint = temp.isReprint;
      tempData.coverType = temp.coverType;
      tempData.title = temp.title;
      tempData.introduction = temp.introduction;
      tempData.author = temp.author;
      tempData.isStick = temp.isStick;
      const newCityNames = [];
      const tempCityNames = temp.cityNames;
      tempCityNames.forEach((item) => {
        const _item = item.pop();
        newCityNames.push({ cityId: _item });
      });
      tempData.informationCityList = newCityNames;
      const categoryId = temp.categoryId;
      if (typeof categoryId === "string") {
        tempData.categoryId = categoryId;
      } else {
        if (categoryId.length === 1) {
          tempData.categoryId = categoryId[0];
        } else {
          tempData.categoryId = categoryId[1];
        }
      }
      if (!this.informationId) {
        tempData.userPoint = 0;
        tempData.userRead = 0;
      }
      tempData.releaseType = temp.releaseType;
      tempData.readBasics = parseInt(temp.readBasics);
      tempData.readMultiple = temp.readMultiple;
      tempData.shareTitle = temp.shareTitle;
      tempData.pointBasics = parseInt(temp.pointBasics);
      tempData.pointMultiple = temp.pointMultiple;
      tempData.shareDescribe = temp.shareDescribe;
      if (temp.upperTime) {
        tempData.upperTime = this.$moment(temp.upperTime).format("YYYY-MM-DD HH:mm:ss");
      }
      if (temp.lowerTime) {
        tempData.lowerTime = this.$moment(temp.lowerTime).format("YYYY-MM-DD HH:mm:ss");
      }
      if (temp.isReprint === 0) {
        tempData.content = temp.content;
      } else {
        tempData.jumpUrl = temp.jumpUrl;
      }
      return tempData;
    },
    handleSave() {
      if (this.informationId) {
        this.updateData();
      } else {
        this.createData();
      }
    },
    // 新增保存
    createData() {
      // console.log('temp.cityNames', this.temp.cityNames);
      if (!this.checkEmpty()) {
        return;
      }
      this.commitFlag = true;
      const tempData = this.handleFormData(this.temp);
      tempData.createUser = sessionStorage.userId;
      tempData.updateUser = sessionStorage.userId;
      tempData.releaseType = this.evil(tempData.releaseType.join("+"));
      var d = new Date();
      d.getFullYear();
      d.getMonth();
      d.getDay();

      d.getHours();

      d.getMinutes();

      d.getSeconds();
      tempData.tempData =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDay() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes() +
        ":" +
        d.getSeconds();
      const opt = {
        url: "/acb/2.0/information/save",
        method: "post",
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        data: tempData,
        success: (res) => {
          this.saveCoverPhoto(res.value);
          this.saveThumbnail(res.value);
        },
      };
      this.$request(opt);
    },
    evil(fn) {
      let Fn = Function;
      return new Fn("return " + fn)();
    },
    // 编辑保存
    updateData() {
      if (!this.checkEmpty()) {
        return;
      }
      this.commitFlag = true;
      const tempData = this.handleFormData(this.temp);
      tempData.informationId = this.informationId;
      tempData.status = 0; // 编辑保存后总是已保存状态
      tempData.updateUser = sessionStorage.userId;
      tempData.releaseType = this.evil(tempData.releaseType.join("+"));
      const opt = {
        url: "/acb/2.0/information/update",
        method: "post",
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        data: tempData,
        success: (res) => {
          this.saveCoverPhoto(this.informationId);
          this.saveThumbnail(this.informationId);
          this.commitFlag = false;
          this.$router.push({ path: "/information" });
        },
      };
      this.$request(opt);
    },
    // 保存封面图片
    saveCoverPhoto(informationId) {
      if (this.image.length === 0) return;
      const baseUrl = "/acb/2.0/information/upload";
      const opt = {
        url: "",
        method: "post",
        data: {},
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        success: (res) => {},
      };
      this.image.forEach((item, index) => {
        const image = new FormData();
        if (item !== undefined) {
          image.append("image", item, "img" + index);
          opt.url =
            baseUrl + "?informationId=" + informationId + "&status=" + (parseInt(index) + 1);
          opt.data = image;
          this.$request(opt);
        }
      });
    },
    // 保存缩略图
    saveThumbnail(informationId) {
      if (this.image2.length === 0) {
        this.commitFlag = false;
        return;
      }
      const fileObj = this.image2[0];
      const image = new FormData();
      image.append("image", fileObj, "img3");
      const opt = {
        url: "/acb/2.0/information/upload?informationId=" + informationId + "&status=4",
        method: "post",
        data: image,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        success: (res) => {
          this.commitFlag = false;
          this.$router.push({ path: "information" });
        },
      };
      this.$request(opt);
    },
    // 表单非空验证
    checkEmpty() {
      if (!this.temp.title) {
        this.$message.warning("标题不能为空");
        return false;
      }
      if (!this.temp.introduction) {
        this.$message.warning("简介不能为空");
        return false;
      }
      if (!this.temp.author) {
        this.$message.warning("作者不能为空");
        return false;
      }
      // if (this.temp.cityNames.length === 0) {
      //   this.$message.warning('发布城市不能为空')
      //   return false
      // }
      if (!this.temp.categoryId) {
        this.$message.warning("分类不能为空");
        return false;
      }
      if (!this.temp.releaseType) {
        this.$message.warning("发布渠道不能为空");
        return false;
      }
      if (!this.temp.readBasics) {
        this.$message.warning("阅读数不能为空");
        return false;
      }
      if (!this.temp.readMultiple) {
        this.$message.warning("阅读放大倍数不能为空");
        return false;
      }
      if (!this.temp.pointBasics) {
        this.$message.warning("点赞数不能为空");
        return false;
      }
      if (!this.temp.pointMultiple) {
        this.$message.warning("点赞放大倍数不能为空");
        return false;
      }
      const reg = /^\d+$/;
      if (
        !reg.test(this.temp.readBasics) ||
        !reg.test(this.temp.readMultiple) ||
        !reg.test(this.temp.pointBasics) ||
        !reg.test(this.temp.pointMultiple)
      ) {
        this.$message.warning("阅读数或者点赞数应为整数");
        return false;
      }
      if (!this.temp.shareTitle) {
        this.$message.warning("分享标题不能为空");
        return false;
      }
      if (!this.temp.shareDescribe) {
        this.$message.warning("分享描述不能为空");
        return false;
      }
      if (this.temp.coverType === 0 && this.fileList.length > 1) {
        this.$message.warning("单图模式只能上传一张图片");
        return false;
      }
      if (this.temp.coverType === 1 && this.fileList.length < 3) {
        this.$message.warning("三图模式必须上传三张图片");
        return false;
      }
      if (this.fileList.length === 0 || this.shareFileList.length === 0) {
        this.$message.warning("封面图片、分享缩略图不能为空");
        return false;
      }
      if (this.temp.isReprint === 1 && !this.temp.jumpUrl) {
        this.$message.warning("转载类型原文链接不能为空");
        return false;
      }
      if (this.temp.isReprint === 0 && !this.temp.content) {
        this.$message.warning("原创类型正文内容不能为空");
        return false;
      }
      if (this.temp.upperTime && this.temp.lowerTime) {
        const upperTime = this.temp.upperTime;
        const lowerTime = this.temp.lowerTime;
        if (upperTime > lowerTime) {
          this.$message.warning("定时上架时间不能大于定时下架时间");
          return false;
        }
      }
      return true;
    },
    // 获取详情
    getInfoDetail() {
      const opt = {
        url: "/acb/2.0/information/detail?informationId=" + this.informationId,
        method: "get",
        data: {},
        success: (res) => {
          this.temp.status = res.value.status;
          this.temp.isReprint = res.value.isReprint;
          this.temp.coverType = res.value.coverType;
          if (res.value.coverType === 0) {
            this.coverLimit = 1;
          } else if (res.value.coverType === 1) {
            this.coverLimit = 3;
          }
          this.temp.title = res.value.title;
          this.temp.introduction = res.value.introduction;
          this.temp.author = res.value.author;
          this.temp.isStick = res.value.isStick;
          this.temp.cityNames = res.value.citySelectIdPath;
          this.temp.categoryId = res.value.categoryId;
          this.temp.userPoint = res.value.userPoint;
          this.temp.userRead = res.value.userRead;
          this.temp.releaseType = res.value.releaseType;
          this.temp.readBasics = res.value.readBasics;
          this.temp.readMultiple = res.value.readMultiple;
          this.temp.shareTitle = res.value.shareTitle;
          this.temp.pointBasics = res.value.pointBasics;
          this.temp.pointMultiple = res.value.pointMultiple;
          this.temp.shareDescribe = res.value.shareDescribe;
          this.temp.upperTime = res.value.upperTime;
          this.temp.lowerTime = res.value.lowerTime;
          this.temp.content = res.value.content;
          this.temp.userRead = res.value.userRead;
          this.temp.userPoint = res.value.userPoint;
          this.temp.jumpUrl = res.value.jumpUrl;
          const infoPreviewImgList = [];
          res.value.infoPreviewImgList.forEach((item) => {
            infoPreviewImgList.push({ url: item });
          });
          this.fileList = infoPreviewImgList;
          this.shareFileList = [{ url: res.value.shareImgUrl }];
          this.hideUploadEdit = this.fileList.length >= this.coverLimit;
          this.hideUploadEdit2 = this.shareFileList.length >= 1;
        },
      };
      this.$request(opt);
    },
    // 修改封面图片模式（1/3图模式）
    coverChange(val) {
      if (val === 1) {
        this.coverLimit = 3;
      } else {
        this.coverLimit = 1;
      }
      this.hideUploadEdit = this.fileList.length >= this.coverLimit;
    },
    // 图片墙删除图片（分享缩略图）
    handleRemoveForShare(file, fileList) {
      this.shareFileList = fileList;
      this.image2 = [];
      if (this.shareFileList.length > 0) {
        this.shareFileList.forEach((item) => {
          this.image2.push(item.raw);
        });
      }
      this.hideUploadEdit2 = this.shareFileList.length >= 1;
    },
    // 图片墙删除图片
    handleRemove(file, fileList) {
      // this.fileList = fileList
      this.fileList = [];
      this.image = [];
      if (fileList.length > 0) {
        fileList.forEach((item) => {
          this.image.push(item.raw);
          this.fileList.push(item.url);
        });
      }

      this.hideUploadEdit = this.fileList.length >= this.coverLimit;
    },
    // 图片超出处理（分享缩略图）
    handleExceedForShare(files, fileList) {
      this.$message.warning("只能上传一张分享缩略图");
    },
    // 图片超出处理
    handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
      const type = this.temp.coverType;
      if (type === 0) {
        this.$message.warning("单图模式只能上传一张图片");
      } else if (type === 1) {
        this.$message.warning("三图模式只能上传三张图片");
      }
    },
    // 上传到阿里云OSS（编辑器上方的上传按钮）
    fnUploadRequest(file) {
      const image = new FormData();
      image.append("image", file.file, "img");
      const opt = {
        url: "/acb/2.0/information/uploadInfo",
        method: "post",
        data: image,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        success: (res) => {
          this.imageUrl = res.value;
        },
      };
      this.$request(opt);
    },
    // 上传到阿里云OSS
    fnUploadRequest2(file) {
      const fileItem = URL.createObjectURL(file.file);
      this.fileList.push(fileItem);
      this.image.push(file.file);
      this.hideUploadEdit = this.fileList.length >= this.coverLimit;
    },
    // 上传到阿里云OSS（分享缩略图）
    fnUploadRequest3(file) {
      const fileItem = URL.createObjectURL(file.file);
      this.shareFileList.push(fileItem);
      this.image2.push(file.file);
      this.hideUploadEdit2 = this.shareFileList.length >= 1;
    },
    // 上传前的验证
    beforeAvatarUpload(file) {
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isJpg = suffix === "jpg";
      const isjpeg = suffix === "jpeg";
      const isPng = suffix === "png";
      const isLt2M = file.size / 1024 < 100;
      if (!isJpg && !isjpeg && !isPng) {
        this.$message.warning("图片格式错误，只支持jpg、jpeg和png格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.warning("上传图片大小不能超过 100kb!");
        return false;
      }
    },
    // 上传前的验证
    beforeAvatarUpload2(file) {
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isJpg = suffix === "jpg";
      const isjpeg = suffix === "jpeg";
      const isPng = suffix === "png";
      const isGif = suffix === "gif";
      const isBmp = suffix === "bmp";
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isJpg && !isjpeg && !isPng && !isGif && !isBmp) {
        this.$message.error("图片格式错误，只支持jpg、jpeg、png、bpm和gif格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
        return false;
      }
    },
    // 预览
    preView() {
      this.previewVisible = true;
    },
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.app-container
  clear both
  .preview-container {
    width: 375px;
    margin: auto;
    .preview-wrapper {
      .title {
        font-size: 16px;
        color: #000;
        line-height: 1.5em;
        padding-bottom: 8px;
        font-weight: bold;
      }
      .tip {
        color: #999;
        font-size: 12px;
        margin: 4px 0 15px 0;
        .type {
          margin-right: 5px;
        }
      }
    }
  }
  .header {
    margin-bottom: 20px;
  }
  .cur {
    color: #FFF;
    background-color: #909399;
    border-color: #909399;
  }
  .label-slot {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    img {
      width: 20px;
      height: 20px;
    }
  }
>>>.hide
  .el-upload--picture-card
    display none
</style>
